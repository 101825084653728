import PrimaryButton from "../components/ui/PrimaryButton";

export default function NotFound() {
  return (
    <div className="bg-gray-100 flex flex-col justify-center items-center h-screen">
      <h1 className="font-bold text-4xl">Page not found</h1>
      <div className="mt-20">
        <PrimaryButton href="/">Go to Home Page</PrimaryButton>
      </div>
    </div>
  );
}
